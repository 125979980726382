<template>
  <div class="cart">
    <h3>Honor And Qualification</h3>
    <h6>荣誉资质</h6>
    <div class="honor-top">
        <ul class="honor-content">
            <li>煤矿与非煤矿产品检验 <span style="color:#007BC7">771</span> 项</li>
            <li>防爆电气产品的CCC认证检验 <span style="color:#007BC7">18</span> 类</li>
            <li>低压成套开关设备CCC认证检验</li>
            <li>矿山安全计量器具检定或校准 <span style="color:#007BC7">17</span> 项</li>
            <li>计量器具型式评价  <span style="color:#007BC7">6</span> 类</li>
        </ul>
    </div>
    <div class="container-hoz">
      <div class="item-list" v-for="(item,index) in imgArry" :key="index">
        <div class="img-content">
          <img :src="item.url" class="img-child" />
        </div>
        <div class="text-content">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "inspection",
  data() {
    return {
       imgArry:[
        {
          url:require("@/assets/u1.png"),
          desc:'安全生产检测检验机构资质证书'
        },
        {
          url:require("@/assets/u2.png"),
          desc:'中国合格评定国家认可委员会实验室证书'
        },
        {
          url:require("@/assets/u3.png"),
          desc:'检验检测机构资质认定证书'
        },
        {
          url:require("@/assets/u4.png"),
          desc:'专项计量授权证书'
        },
        {
          url:require("@/assets/u5.png"),
          desc:'计量授权证书'
        },
        {
          url:require("@/assets/u6.png"),
          desc:'国际认证检测资质'
        },
        {
          url:require("@/assets/u7.png"),
          desc:'国际认证检测资质'
        },
        {
          url:require("@/assets/u8.png"),
          desc:'营业执照证书'
        },
        {
          url:require("@/assets/u9.png"),
          desc:'高新技术企业证书'
        },
        {
          url:require("@/assets/u10.png"),
          desc:'重庆是专精特新企业'
        },
        {
          url:require("@/assets/u11.png"),
          desc:'资产证书'
        },
        {
          url:require("@/assets/u12.png"),
          desc:'资产认定证书'
        },
       ]
    };
  },
};
</script>
<style scoped lang="scss">
.cart {
  text-align: center;
  overflow: auto;
}
h6 {
  margin-bottom: 18px;
}

.container-hoz {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item-list {
    width: 32%; /* Each item takes up 1/3 of the container width */
    height: 408px;
    border-radius: 16px;
    margin-bottom:32px;
    .img-content {
      height: 308px;
      background: rgba(148, 160, 162, 0.24);
      padding: 19px;
      position: relative;
      .img-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /* 或者水平垂直居中 */
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .text-content {
      height: 62px;
      line-height: 62px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      box-shadow: 0px 1px 28px 1px rgba(0, 0, 0, 0.04);
    }
  }
}

.honor-content{
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin:0px;
    width:100%;
    padding:0px;
    margin-bottom:31px;
    li{
        height: 40px;
        background: #F7F7F7;
        border-radius: 8px 8px 8px 8px;
        line-height:40px;
        width:calc(33.33% - 10px);
        margin:5px;
    }

}
</style>
