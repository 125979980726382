<template>
  <div class="cart">
    <h3>Development Path</h3>
    <h6>发展历程</h6>
    <el-timeline class="qz-develop-time">
      <el-timeline-item
        :class="{ active: activity == currTime }"
        @click.native="timeClick(activity)"
        v-for="activity in activities"
        :key="activity"
        :timestamp="activity"
        placement="bottom"
      ></el-timeline-item>
    </el-timeline>
    <div class="text">
      <p class="year">{{ currTime + "年" }}</p>
       <div v-html="thing[activities.indexOf(currTime)]"></div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'develop',
    data() {
        return {
            currTime: '2024',
            activities: ['2024','2022', '2021', '2019', '2013', '2008', '2004', '1993', '1992', '1989', '1988', '1971', '1965'],
            thing: [
                '2024年04月，国家认监委公告"2024年底5号"，"重庆安标检测研究院有限公司（国家安全生产重庆矿用设备检测检验中心）"被指定为"低压成套开关设备强制性产品认证检验实验室"。<br/>2024年04月，"重庆安标检测研究院有限公司"获得"IECEx ExTL(防爆检验实验室"。<br/>2024年07月，"煤炭工业重庆电气防爆检验站"签约成为"欧盟ATEX资质认证机构斯洛伐克检测技术股份公司"的合作实验室。<br/>2024年08月，"重庆安标检测研究院有限公司"获得"TüV茵检测认证服务（中国）有限公司电器产品CE授权实验室"证书。',
                '06月，整体搬迁至建桥新基地。',
                '08月，国家认证认可监督管理委员会公告"2019年 第18号"，"国家煤矿防尘通风安全产品质量监督检验中心"被指定为防爆电气产品强制性产品认证检验实验室。',
                '11月，注册成立法人机构--"重庆安标检测研究院有限公司"。',
                '04月经国家质量监督检验检疫总局以"（国）法计（2008）02003号文"批准、授权成立法定型式评价实验室--"国家矿用计量器具型式评价实验室（重庆）"；后更名为"国家烟尘粉尘测量仪型式评价实验室（重庆煤矿）"和"国家易燃易爆气体检测（报警）仪型式评价实验室（重庆煤矿）"。',
                '05月，经国家煤矿安全监察局以"（2004）安监管检字A003号证书"批准、授权成立国家级安全生产产品质量监督检验机构--"国家安全生产重庆矿用设备检测检验中心"。',
                '08月，经原煤炭工业部以"煤厅字（1993）371号文"批准、授权成立煤炭行业产品质量监督检验站--"煤炭工业重庆电气防爆检验站"。',
                '07月，经原国家技术监督局以"技监局量发（1992）337号文"批准、授权成立国家级法定计量检定机构--"国家矿山安全计量站"。',
                '12月，经原能源部以"能源技（1989）1268号文"批准、授权成立煤炭行业产品质量监督检验站--"煤炭工业重庆安全仪器检验站"。',
                '08月，经原国家技术监督局以"技监局监发（88）25号文"批准、授权成立国家级产品质量监督检验机构--"国家煤矿防尘通风安全产品质量监督检验中心"。',
                '电气防爆检验站成立，经原煤炭工业部授权正式对外开展防爆检验业务。',
                '依托重庆研究院筹建电气防爆检验站。'
            ]
        }
    },
    methods: {
        timeClick(time) {
            this.currTime = time;
        }
    }
}
</script>
<style scoped>
h6 {
  margin-bottom: 50px;
  line-height: 22px;
}

.text {
  width: 953px;
  margin-left: 90px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666;
  line-height: 24px;
}

.year {
  width: 232px;
  line-height: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c3c3c3;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333;
}

.qz-develop-time  .el-timeline-item{
    margin-bottom:30px;
}




.qz-develop-time.el-timeline/deep/ .el-timeline-item .el-timeline-item__wrapper{
    padding-left: 44px;
}
</style>
