<template>
    <div class="cart">
        <h3>Business Scope</h3>
        <h6>业务范围</h6>
        <div class="box">
            <h4>01 认证检验业务</h4>
            <h5>安标认证检验：</h5>
            <p>仪器仪表、系统、救护、通风、除降尘、高低压电器、非金属、提升运输、隔抑爆、高分子材料、泵类等795种产品的安标MA/KA/CMAC认证检验及监督检验</p>
            <h5>强制性产品认证（CCC)检验：</h5>
            <p>①电机、电泵、开关、控制及保护产品、监控产品、通风设备、仪器仪表类产品等18大类防爆产品的强制性产品认证（CCC)检验及监督检验</p>
            <p>②低压成套开关设备的强制性产品认证（CCC)检验及监督检验</p>
            <h5>国际认证检验：</h5>
            <p>①IECEx国际认证、ATEX欧盟认证防爆检验</p>
            <p>②TüV莱茵认证电器产品CE检测</p>
            <h5>国际认证检验：</h5>
            <p>甲烷测定器、流量计、差压测量仪、粉尘采样器、直读式粉尘浓度测量仪（含传感器）等6类计量器具的型式评价检验</p>


            <h4 style="margin-top: 16px;">02委托检验业务</h4>
            <h5>政府委托检验：</h5>
            <p>国家和地方政府产品质量监督抽查检验、仲裁检验、事故调查检验</p>
            <h5>防爆委托检验：</h5>
            <p> I、II、III类电气产品的防爆检验</p>
            <h5>性能委托检验：</h5>
            <p> 授权范围内795种产品的性能检验</p>
            <h5>在用品委托检验：</h5>
            <p> 通风机、瓦斯抽放泵等在用产品检验</p>
            <h5>进货验收委托检验：</h5>
            <p>授权范围内795种产品的进货验收检验</p>
            <h5>能效委托检验：</h5>
            <p>通风机、中小型电动机产品的能效检验</p>
            <h5> 机械环境可靠性委托检验：</h5>
            <p>产品振动、冲击、运输等机械环境可靠性检验</p>
            <h5> 气候环境可靠性委托检验：</h5>
            <p> 产品高温、低温、交变湿热等气候环境可靠性检验</p>
            <h5>  电磁兼容(EMC)委托检验：</h5>
            <p> 产品电磁兼容性检验</p>
            <h5>  其他委托检验：</h5>
            <p> 科研性、样机试验、定型鉴定等检验</p>

            <h4 style="margin-top: 16px;">计量检定或校准</h4>
            <h5> 计量检定：</h5>
            <p>  全国范围内涉及矿山安全的光干、可燃气体，粉尘、风速等17项计量标准器具和工作计量器具的检定</p>
            <h5>   计量校准/检测：</h5>
            <p>全国范围内涉及矿山安全的光干、可燃气体，粉尘、风速等18项计量标准器具和工作计量器具的校准或检测</p>
          

            <h4 style="margin-top: 16px;">04设备防爆安全检查业务</h4>
            <p>石油、化工、船舶、港口、燃气工程、制药、机械、纺织、电力、轻工、粮食和木材加工及贮存等行业易燃易爆危险场所的设备防爆安全检查</p>

            <h4 style="margin-top: 16px;">05培训业务</h4>
            <p>矿山安全专业计量检定、校准人员培训，产品质量检验人员培训，防爆安全员培训服务等</p>

            <h4 style="margin-top: 16px;">06技术服务业务</h4>
            <p>产品设计防爆技术咨询、计量建标咨询、实验室建设咨询等</p>

            <h4 style="margin-top: 16px;">07计量检测设备</h4>
            <p>光干涉甲烷测定器检定装置、风速测量仪表检定装置、粉尘浓度测量仪表检定装置、气体测定器综合检定装置等20种实验室标准检验设备及甲烷等气体标准物质</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'business'
}
</script>
<style scoped>
h6 {
    margin-bottom: 18px;
}

h4 {
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
}

h5,p {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #666666;
    line-height: 21px;
}

h5{
    font-weight: bold;
}
p{
    margin-bottom: 16px;
}
</style>