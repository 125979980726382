<template>
    <div class="cart" style="overflow:auto;">
        <h3>Introduction</h3>
        <h6>简介</h6>
        <div class="clearfix" style="display: flex;">
            <div class="fl" style="width: 742px;margin-right: 90px;height: 100%;overflow: auto;">
                <p class="pText">  重庆安标检测研究院有限公司（以下简称“重庆安标检测”）始建于1965年，国家高新技术企业、重庆市“专精特新”企业，是中央企业中国煤炭科工集团重庆研究院有限公司的全资子公司，是集检验检测、计量、检测技术研究、培训及技术服务等于一体，具有第三方公正地位的综合性检测技术服务机构。</p>
                <p class="pText"> 重庆安标检测与国家安全生产重庆矿用设备检测检验中心、国家煤矿防尘通风安全产品质量检验检测中心、国家矿山安全计量站、国家烟尘粉尘测量仪型式评价实验室（重庆煤矿）、国家有毒有害、易燃易爆气体检测（报警）仪型式评价实验室(重庆煤矿)、煤炭工业重庆电气防爆检验站、煤炭工业重庆安全仪器检验站是一个实验室资源平台上的八个不同机构，是原国家质量监督检验检疫总局、中国国家认证认可监督管理委员会、国家安全生产监督管理总局授权的国家级检测检验机构和国家法定计量机构。</p>
                <p class="pText"> 重庆安标检测下设办公室、检验技术部、质量监督部、安全管理部、市场部5个职能部门，防爆、仪器仪表、电器与传动、安全装备、防隔爆设施5个检测研究所和1个检测技术研究所；现有职工116人，其中专业技术人员95人，占职工总人数的82%；工程师及以上专业技术人员77人，占职工总人数的66%；其中副高级职称35人、正高级职称10人，占职工总人数的39%；拥有注册安全工程师30人，占专业技术人员的31.6%。现有实验室建筑面积40000平方米和两条大型实物模拟试验巷道，其中试验室面积36500平方米；固定资产（不计两条大型实物模拟试验巷道）原值近4亿元，主要检验设备2100余台（套）。</p>
                <p class="pText">重庆安标检测现有CMA资质检测能力795项、CNAS认可能力777项、安全生产资质513项、强制性产品认证（CCC）授权防爆电气检验项目18类及低压成套开关设备1类、计量授权检定项目17项及校准项目18项、计量器具型式评价授权6项、IECEx国际认证检验项目4项；可承担国家产品质量监督抽查检验、安标MA/KA/CMAC认证产品检验、防爆电气产品和低压成套开关设备强制性产品认证（CCC）检验、IECEx防爆国际认证检验、ATEX防爆欧盟认证检验、TüV莱茵电器产品CE检测、计量器具型式评价检验、委托检验、事故调查检验、仲裁检验、计量检定及校准等任务，还开展检定人员、检测人员技术培训、计量检测技术和计量标准装置研究等工作。</p>
                <p class="pText"> 重庆安标检测近六十来年，先后主持或参与国家/省部级课题30余项，制/修订国标、行标、计量规范近100余项；是煤炭行业煤矿安全标委员会通风技术及设备分会的主任委员及秘书长单位，是全国环境化学计量技术委员会、全国防爆电气标准化委员会、全国粉尘防爆标准化委员会等11个专业技术组织的委员单位。</p>
                <p class="pText"> 重庆安标检测先后与SITIIAS、CQM、安标国家中心等机构签署IECEx国际认证检测合作协议；与CQC、CQM、CNEX、PCEC等6家强制性产品认证机构签署合作协议；签约成为欧盟ATEX资质认证机构斯洛伐克检测技术股份公司的合作实验室；获得TüV莱茵检测认证服务（中国）有限公司电器产品CE授权实验室资质；与美国、南非等多国保持着长期的科技合作与交流，并与国内各大煤企、高校合作开展检测技术研究。</p>
                <p class="pText"> 在中国煤科和重庆研究院的正确领导下,重庆安标检测将以饱满昂扬的斗志 ,改革创新的姿态,紧紧围绕总体战略规划,坚持安全检测主业发展方向 ,努力成长为“国内领先、国际一流的综合性检测技术服务机构”，推动行业技术发展进步！</p>
            </div>
            <img width="320" height="440" src="~@/assets/home.png" style="border-radius: 17px;"/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'home'
}
</script>